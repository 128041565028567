export default function IconClose() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 371.23 371.23"
      width="100%"
      height="100%"
      xmlSpace="preserve"
    >
      <polygon
        style={{ fill: "currentColor" }}
        points="371.23,21.213 350.018,0 185.615,164.402 21.213,0 0,21.213 164.402,185.615 0,350.018 21.213,371.23 
	185.615,206.828 350.018,371.23 371.23,350.018 206.828,185.615 "
      />
    </svg>
  );
}
